import { graphql, useStaticQuery } from 'gatsby'
import * as React from 'react'
import Layout from '../components/Layout/Layout'
import Seo from '../components/Seo/Seo'
import config from '../../config.json'
import { renderToString } from 'react-dom/server'
import '../scss/pages/articles.scss'
import PostBox from '../components/PostBox/PostBox'
import InfoBox from '../components/InfoBox/InfoBox'

const ArticlesPage = () => {
    const data = useStaticQuery(query);
    const seo = data.wpPage.seo;
    let articlesCount = config.ARTICLES_COUNT;
  
    const topPosts = data.allWpPost.edges.slice(0, 2);
    const bottomPosts = data.allWpPost.edges.slice(2, 2 + articlesCount);
    const storagePosts = data.allWpPost.edges.slice(2 + articlesCount);

    if(typeof window !== 'undefined'){
        sessionStorage.setItem('posts', JSON.stringify(storagePosts));
      }
      if(typeof document !== `undefined`) {
        document.addEventListener('scroll', function(e) {
          if(Boolean(document.getElementsByClassName('infinite-scroll').length) && typeof window !== 'undefined') {
            let scrollPosition = window.scrollY;
            var lastPost = document.querySelector('.post-container:last-child');

            if(scrollPosition > lastPost.offsetTop) {
              let postsList = JSON.parse(sessionStorage.getItem('posts'));
              let postsToShow = postsList.slice(0, articlesCount);
              
              sessionStorage.setItem('posts', JSON.stringify(postsList.slice(articlesCount)));
    
              let html = '';
    
              postsToShow.forEach(post => {
                html += `<div class="col-md-4 mb-30 post-container">`+ renderToString(<PostBox post={post.node} />) +`</div>`;
              });
    
              document.querySelector('.infinite-scroll').innerHTML += html;
            }
          }
        });
      }
 
    return (
        <Layout>
            <Seo title={seo.title} description={seo.metaDesc} keywords={seo.focuskw} pathname="/poradniki/" />
            <div className="page-top-section pb-90">
                <div className="container custom-container">
                    <div className="row">
                        <div className="col-md-12">
                        <p className="small-header text-center mb-0 text-white">Strefa wiedzy</p>
                        <h1 className="header-h1 text-center mt-15 mb-30 text-white">Poradniki ubezpieczeniowe</h1>
                        <p className="paragraph text-center text-white font-light">Skorzystaj z wiedzy naszych specjalistów. Dowiedz się więcej o ubezpieczeniach na życie do kredytu hipotecznego i wybieraj świadomie.</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="top-posts">
                <div className="container">
                    <div className="row display-flex posts-row">
                        {topPosts.map((post, index) => {
                             return <div className="col-md-6 mb-30"><PostBox post={post.node} /></div>
                        })}
                    </div>
                </div>
            <InfoBox />
            </div>
            <div className="bottom-posts">
                <div className="container">
                    <div className="row display-flex infinite-scroll">
                        {bottomPosts.map((post, index) => {
                            return <div className="col-md-4 mb-30 post-container"><PostBox post={post.node} /></div>
                        })}
                    </div>
                </div>
            </div>
        </Layout>
    )
}

export default ArticlesPage

const query = graphql`
{
    wpPage(slug: {eq: "poradniki"}) {
      seo {
        metaDesc
        title
        focuskw
      }
    }
    allWpPost(
      filter: {categories: {nodes: {elemMatch: {name: {eq: "Poradniki"}}}}}
      sort: {fields: date, order: DESC}
    ) {
      edges {
        node {
          title
          content
          featuredImage {
            node {
              altText
              mediaItemUrl
              localFile {
                childImageSharp {
                  gatsbyImageData(width: 600)
                }
              }
            }
          }
          poradniki {
            zajawka
          }
          slug
          dateGmt(formatString: "DD MMMM YYYY", locale: "pl")
        }
      }
    }
}
`
